/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import ngFileUpload from 'ng-file-upload';
import _ from 'lodash';
import {ApiConfig} from "../api/api";

const MODULE_NAME = "content-summary-helper";

angular.module(MODULE_NAME, [
  ngFileUpload
])

  .factory('contentSummaryHelper', function($http) {
    return {
      getProfile: function(id, url) {
        return $http.get(ApiConfig.obtain().getVersionedUri(['contentsummary']), {
          params: _.assign({}, {format: 'json', concise: true, site: id, url: url}),
          hasErrorHandler: true
        }).then(_.iteratee('data')).catch(function(err) {
          return err.data;
        });
      },
      getAdCount: function(id) {
        return $http.get(ApiConfig.obtain().getVersionedUri(['contentsummary', 'adcount']), {
          params: _.assign({}, {site: id}),
          hasErrorHandler: true
        }).then(_.iteratee('data')).catch(function(err) {
          return err.data;
        });
      },
      getAdCounts: function(id) {
        return $http.get(ApiConfig.obtain().getVersionedUri(['contentsummary', 'adcounts']), {
          params: _.assign({}, {site: id}),
          hasErrorHandler: true
        }).then(_.iteratee('data')).catch(function(err) {
          return err.data;
        });
      }
    };
  });

export default MODULE_NAME;