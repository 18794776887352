/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import {IAB_TAXONOMY} from "../../components/util/iab-taxonomy";

import iabSearchSelect from '../common/controller/iab-multi-select-component';
import standardFormModule from '../common/standard-form-module';
import {adnFormHelper} from "../common/controller/form-helper";
import allowedTechSchibsted from "./allowed-tech-schibsted.html";
import allowedTechSchibstedSweden from "./allowed-tech-schibsted-sweden.html";
import allowedTechTv2 from "./allowed-tech-tv2.html";

const MODULE_NAME = 'site-controller';

angular.module(MODULE_NAME, [standardFormModule, iabSearchSelect])

  .controller('SiteCtrl', function($stateParams, $templateCache, earningsAccountFromParam, localUserProfile, adnListHelper, LocalUserPermissions, LocalNetworkProfile, SiteGroup, Site, Blocklist, Ruleset, EarningsAccount, Team, model, modelComms, searchResource) {
    const ctrl = this;

    ctrl.showBlockLists = 'ACTIVE';
    ctrl.showScannerBlockedCreatives = LocalNetworkProfile.isExperimental();
    ctrl.pureMarketplacePlatform = LocalNetworkProfile.isPureMarketplacePlatform();

    ctrl.showSiteGroupsEditable = !ctrl.pureMarketplacePlatform || LocalUserPermissions.hasAny("MANAGE_PUBLISHING");

    ctrl.isNew = $stateParams.isNew;
    ctrl.isAxPublisherAdmin = LocalUserPermissions.hasNetworkPermission('AX_PUBLISHER_ADMIN');
    ctrl.isAxPublisher = LocalUserPermissions.isAnyAxPublisher();

    if (ctrl.isNew && earningsAccountFromParam) {
      model.earningsAccount = {id: earningsAccountFromParam.id, name: earningsAccountFromParam.name};
      model.country = earningsAccountFromParam.country;
      model.ownerTeam = {id: earningsAccountFromParam.ownerTeamId};
    }

    let username = localUserProfile.get("username");
    if (_.includes(["adnuntius@adnuntius.com", "broker1@adnuntius.com"], username)) {
      ctrl.adminUserName = username;
      ctrl.showPaywalled = true;
      if (ctrl.showScannerBlockedCreatives) {
        ctrl.showScannerToggle = true;
      }
    }

    adnFormHelper.setUpForm(ctrl, model, {
      modelComms: modelComms, afterInit: function() {
        ctrl.model.renderOption = ctrl.model.renderOption || null;
        ctrl.model.filterTeamsType = ctrl.model.filterTeamsType || 'NONE';

        if (ctrl.showScannerBlockedCreatives && ctrl.model.scanCreativesPolicy) {
          ctrl.adnCreativeSummary = {};
          adnListHelper.setUpBasicList(ctrl.adnCreativeSummary, Site, '', {
            listMethod: 'adnCreativeSummary',
            listGetMethod: 'adnCreativeSummary',
            moreParams: {
              id: model.id
            },
            afterFill: function(data) {
              ctrl.summaryBlocked = _.filter(data, function(datum) {
                return datum.status === 'BLOCKED';
              });
              ctrl.summaryBlockedUniq = _.uniqBy(ctrl.summaryBlocked, 'hash').length;
              ctrl.summaryInitiallyBlocked = _.filter(data, function(datum) {
                return datum.status === 'INITIALLY_BLOCKED';
              });
              ctrl.summaryInitiallyUniq = _.uniqBy(ctrl.summaryInitiallyBlocked, 'hash').length;
              ctrl.summaryAllowed = _.filter(data, function(datum) {
                return datum.status === 'ALLOWED';
              });
              ctrl.summaryAllowedUniq = _.uniqBy(ctrl.summaryAllowed, 'hash').length;
            }
          });
        }
      }, afterSave: function(data) {
        ctrl.teamsWarning = (_.get(ctrl.model, 'teams') || []).length !== (_.get(data, 'teams') || []).length;
        ctrl.model.renderOption = ctrl.model.renderOption || null;
      }, afterInitAfterSave: function(data) {
        _.set(ctrl.model, 'teams', _.map(_.get(data || ctrl.model, 'teams') || [], 'id'));

        ctrl.model.scanCreativesPolicy = (data || ctrl.model).scanCreativesPolicy ? (data || ctrl.model).scanCreativesPolicy : null;

        if (model.scanCreativesPolicy === 'SAFE_DOMAIN_COOKIE_500') {
          $templateCache.put("allowedTech.html", allowedTechSchibsted);
        }
        if (model.scanCreativesPolicy === 'TV2_NO_COOKIE_500') {
          $templateCache.put("allowedTech.html", allowedTechTv2);
        }
        if (model.scanCreativesPolicy === 'SCHIBSTED_SWEDEN') {
          $templateCache.put("allowedTech.html", allowedTechSchibstedSweden);
        }
      }
    });

    ctrl.iabTaxonomy = angular.copy(IAB_TAXONOMY);

    EarningsAccount.query({minimal: true}, function(page) {
      ctrl.earningsAccounts = page.results;

      if (ctrl.model.earningsAccount && ctrl.model.earningsAccount.id) {
        ctrl.model.earningsAccount = _.find(ctrl.earningsAccounts, ['id', ctrl.model.earningsAccount.id]);
      }
    });

    SiteGroup.query({minimal: true}, function(page) {
      ctrl.siteGroups = page.results;

      if (ctrl.model.siteGroup && ctrl.model.siteGroup.id) {
        ctrl.model.siteGroup = _.find(ctrl.siteGroups, ['id', ctrl.model.siteGroup.id]);
      }
    });

    Blocklist.query({minimal: true}, function(page) {
      ctrl.blocklists = page.results;

      if (ctrl.model.blocklist && ctrl.model.blocklist.id) {
        ctrl.model.blocklist = _.find(ctrl.blocklists, ['id', ctrl.model.blocklist.id]);
      }
    });

    if (ctrl.pureMarketplacePlatform) {
      Ruleset.query({minimal: false}, function(page) {
        ctrl.siteRulesets = _.filter(page.results, function(rs) {
          return rs.type === 'INVENTORY';
        });
        ctrl.targetingRulesets = _.filter(page.results, function(rs) {
          return rs.type === 'TARGETING';
        });
        ctrl.sizeRulesets = _.filter(page.results, function(rs) {
          return rs.type === 'SIZE';
        });
        if (ctrl.model.siteRuleset && ctrl.model.siteRuleset.id) {
          ctrl.model.siteRuleset = _.find(page.results, ['id', ctrl.model.siteRuleset.id]);
        }
        ctrl.rulesets = page.results;
      });
    }

    ctrl.countryChangeHook = function() {
      ctrl.editsMade();
    };

    ctrl.selectTeams = function() {
      ctrl.editsMade();
      ctrl.model.teams = _.map(ctrl.allNotAxPublisherTeams, 'id');
    };

    ctrl.deselectTeams = function() {
      ctrl.editsMade();
      ctrl.model.teams = [];
    };

    ctrl.lineItemSearch = function(searchElement) {
      searchResource.query({"q": searchElement.search, "types": "LineItem"}).then(function(data) {
        ctrl.lineItemSearchResults = data.searchResults;
      });
    };

    ctrl.creativeSearch = function(searchElement) {
      searchResource.query({"q": searchElement.search, "types": "Creative"}).then(function(data) {
        ctrl.creativeSearchResults = data.searchResults;
      });
    };

    ctrl.teamSearch = function(searchElement) {
      searchResource.query({"q": searchElement.search, "types": "Team"}).then(function(data) {
        ctrl.teamSearchResults = data.searchResults;
      });
    };

    Team.query({minimal: true}).$promise.then(function(page) {
      ctrl.allTeams = page.results;
      ctrl.allMarketplacePublisherTeams = _.filter(ctrl.allTeams, function(team) {
        return team.type === 'AX_PUBLISHER';
      });
      ctrl.allNotAxPublisherTeams = _.filter(ctrl.allTeams, function(team) {
        return team.type !== 'AX_PUBLISHER' && team.type !== 'CREATIVE_AGENCY';
      });
      ctrl.showOwnership = ctrl.allMarketplacePublisherTeams.length > 0;
    });

    ctrl.canUpdateSite = LocalUserPermissions.hasNetwork('MANAGE_SYSTEM') || LocalUserPermissions.hasAny('MANAGE_PUBLISHING') || LocalUserPermissions.hasNetwork('AX_PUBLISHER_ADMIN');

    ctrl.onBeforeSubmit = function() {
      if (!ctrl.model.country) {
        ctrl.model.country = null;
      }
      ctrl.model.iabCategories = _.map(ctrl.model.iabCategories, function(iab) {
        return _.get(iab, ['id'], iab);
      });
      ctrl.model.siteGroups = _.map(ctrl.model.siteGroups, function(sg) {
        return {id: sg.id, name: sg.name};
      });
      if (ctrl.model.ownerTeam) {
        ctrl.model.teams = _.filter(ctrl.model.teams, function(teamId) {
          return !!_.find(ctrl.allNotAxPublisherTeams, function(team) {
            return team.id === teamId;
          });
        });
      }
    };
  });

export default MODULE_NAME;