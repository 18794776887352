/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import standardAnalysisModule from '../common/standard-analysis-module';
import {adTagLinker} from "../ad-unit/ad-tag-linker";
import {adUnitBulkAction} from "../ad-unit/ad-unit-commons";
import {columnFinder} from "../common/controller/resource-list-controller";
import contentSummaryHelper from "../../components/util/content-summary-helper";
import {convertIabCategoryToString} from "../../components/util/iab-taxonomy";
import {LocalNetworkInfo} from "../../components/session/local-network-info";

const MODULE_NAME = "site-analysis";

angular.module(MODULE_NAME, [standardAnalysisModule, contentSummaryHelper])

  .controller('SiteAnalysisCtrl', function($stateParams, $log, $state, $window, crawlerResource, recentArticlesSearchResource, contentSummaryHelper, timezonesResource, ConsentStatistic, Site, LocalNetworkProfile, $uibModal, model, modelComms, AdUnit, localUserProfile, LocalUserPermissions, adnListHelper) {
    const ctrl = this;
    ctrl.query = {};
    ctrl.model = angular.copy(model);

    ctrl.purposeMatrix = {
      1: "Store and/or access information on a device",
      2: "Select basic ads",
      3: "Create a personalized ads profile",
      4: "Select personalized ads",
      7: "Measure ad performance"
    };
    const relevantPurposes = [1, 2, 4];

    ctrl.auFields = ['name', {param: '', label: 'dimensions'}, {label: 'status', param: 'requestedInLastDay'}, {label: 'tagId'}];
    const isSpecialUser = localUserProfile.get("username") === "adnuntius@adnuntius.com" || localUserProfile.get("username") === "broker1@adnuntius.com";
    if (LocalNetworkProfile.isPureMarketplacePlatform() && isSpecialUser) {
      ctrl.auFields.push({'label': 'isFullPage', 'param': 'fullPage'});
    }
    ctrl.columnFinder = function(column, param) {
      return columnFinder(ctrl, column, param);
    };

    let username = localUserProfile.get("username");
    if (_.includes(["adnuntius@adnuntius.com", "broker1@adnuntius.com"], username)) {
      ctrl.showAdCount = true;
    }


      ctrl.queryUrl = function() {
      ctrl.queryRunning = true;
      ctrl.info = {};
      contentSummaryHelper.getProfile(model.id, ctrl.theUrl).then(function(info) {
        ctrl.queryRunning = false;
        ctrl.infoError = false;

        if (_.get(info, ['parameters', 'errorCode']) === 'ECFSR001') {
          ctrl.infoError = true;
        } else if (_.get(info, ['content'])) {
          info.contentTypes = [];
          _.forEach(info.content, function(val, key) {
            if (key === 'categories') {
              info.categoriesOutput = _.map(val, function(cat) {
                return convertIabCategoryToString(cat);
              }).join(", ");
            } else {
              info[key + 'Output'] = val.join(", ");
            }
            info.contentTypes.push(key);
          });
        }
        ctrl.info = info;
      });
    };

    ctrl.adCountRunning = false;
    ctrl.getAdCount = function() {
      ctrl.adCountRunning = true;
      ctrl.adCount = {};
      contentSummaryHelper.getAdCount(model.id).then(function(adCount) {
        ctrl.adCountRunning = false;
        ctrl.adCount = adCount;
      });
    };

    ctrl.adCountsRunning = false;
    ctrl.getAdCounts = function() {
      ctrl.adCountsRunning = true;
      ctrl.adCounts = {};
      contentSummaryHelper.getAdCounts(model.id).then(function(adCounts) {
        ctrl.adCountsRunning = false;
        ctrl.adCounts = adCounts;
      });
    };

    ctrl.showContentProfile = -1;
    ctrl.canDoContentProfiles = function() {
      if (!ctrl.model.siteUrl) {
        ctrl.showContentProfile = 0;
        return;
      }
      ctrl.showContentProfile = LocalNetworkInfo.obtain().getInfo("contextServiceConfigurationCount");
    };
    ctrl.showConsent = LocalNetworkProfile.isPureMarketplacePlatform();
    ctrl.canDoContentProfiles();

    function getDomainFromUrl(url) {
      if (url.indexOf("http") !== 0) {
        return "";
      }
      return new URL(url).hostname;
    }

    ctrl.getRecentArticles = function() {
      ctrl.recentArticlesRunning = true;
      recentArticlesSearchResource.query({domain: getDomainFromUrl(model.siteUrl), maxAgeInHours: 24}).then(function(page) {
        ctrl.recentArticles = page.results;
        _.forEach(ctrl.recentArticles, function(article) {
          article.keywordsRead = article.keywords.join(", ");
        });
      }).finally(function() {
        ctrl.recentArticlesRunning = false;
      });
    };

    ctrl.getViewedArticles = function() {
      ctrl.viewedArticlesRunning = true;
      recentArticlesSearchResource.viewed({domain: getDomainFromUrl(model.siteUrl)}).then(function(page) {
        ctrl.viewedArticles = page.results;
        _.forEach(ctrl.viewedArticles, function(article) {
          article.keywordsRead = article.keywords.join(", ");
        });
      }).finally(function() {
        ctrl.viewedArticlesRunning = false;
      });
    };

    ctrl.triggerProfileRefresh = function() {
      ctrl.triggerDone = true;
      crawlerResource.refresh(ctrl.theUrl).then(function() {
        ctrl.triggerMessage = 1;
      }).catch(function() {
        ctrl.triggerMessage = 0;
      }).finally(function() {
        ctrl.triggerDone = false;
      });
    };

    ctrl.queryConsent = function() {
      if (!ctrl.model.siteUrl) {
        return;
      }
      ctrl.noConsentResults = false;
      if (!ctrl.siteHost) {
        ctrl.noConsentResults = true;
      }
      ctrl.isRunning = true;
      ConsentStatistic.query({site: model.id}, function(data) {
        ctrl.consent = data;

        if (_.get(ctrl.consent, ['purpose'])) {
          ctrl.consent.purpose = _.pick(ctrl.consent.purpose, relevantPurposes);
        }

        _.forEach(_.get(ctrl.consent, ['purpose']), function(p) {
          p.maxPercent = Math.max(p.consentedPercent, p.legitimateInterestPercent);
          p.totalUsersPercent = ctrl.consent.percentWithConsentString * p.maxPercent / 100;
          p.htmlClass = p.totalUsersPercent > 94.9 ? 'text-success' : p.totalUsersPercent > 74.9 ? 'text-warning' : 'text-danger';
          p.failures = [];
          if (ctrl.consent.percentWithConsentString < 99.95) {
            p.failures.push({percentage: (100 - ctrl.consent.percentWithConsentString), reason: "without a consent string"});
          }
          if (p.consentedPercent > p.legitimateInterestPercent && p.consentedPercent < 99.95) {
            p.failures.push({percentage: (100 - p.consentedPercent), reason: "did not consent"});
          }
          if (p.legitimateInterestPercent > p.consentedPercent && p.legitimateInterestPercent < 99.95) {
            p.failures.push({percentage: (100 - p.legitimateInterestPercent), reason: "opted out of legitimate interest"});
          }
        });

        if (_.get(ctrl.consent, ['purpose'])) {
          _.forEach(relevantPurposes, function(purposeKey) {
            if (!ctrl.consent.purpose[purposeKey]) {
              ctrl.consent.purpose[purposeKey] = {
                totalUsersPercent: 0,
                htmlClass: 'text-danger',
                failures: [{percentage: 100, reason: "without a value at all"}]
              };
            }
          });
        }

        ctrl.isRunning = false;
      }, function() {
        ctrl.noConsentResults = true;
        ctrl.isRunning = false;
      });
    };

    if (model.siteUrl) {
      try {
        ctrl.siteHost = (new $window.URL(model.siteUrl)).hostname;
      } catch (e) {
        $log.error(e);
      }
    }

    ctrl.loadAdUnitForm = function() {
      ctrl.adUnit = AdUnit.create({
        site: ctrl.model
      });
      ctrl.showAdUnitForm = true;

      ctrl.adUnit.dimensions = _.isArray(ctrl.adUnit.dimensions) ? ctrl.adUnit.dimensions : [[]];
      ctrl.adUnit.dimensions[0] = _.isArray(ctrl.adUnit.dimensions[0]) ? ctrl.adUnit.dimensions[0] : [];
      ctrl.adUnit.renderOption = ctrl.adUnit.renderOption || null;

      const isFixed = _.get(ctrl.adUnit, ['dimensions', 0, 0]) >= 0 && _.get(ctrl.adUnit, ['dimensions', 0, 1]) >= 0;
      ctrl.adUnit.adUnitOption = ctrl.adUnit.adUnitOption || (isFixed ? 'FIXED' : 'RANGE');
    };

    ctrl.saveAdUnit = function(promise) {
      promise.then(function(adUnit) {
        ctrl.showAdUnitForm = false;
        if (!_.isArray(ctrl.items)) {
          ctrl.items = [];
        }
        ctrl.addItemToList(adUnit);
      });
    };

    ctrl.canSeeReports = LocalUserPermissions.getAnyVisibility().RUN_REPORTS === true
      || LocalUserPermissions.getAnyVisibility().AX_PUBLISHER === true;
    ctrl.showingChart = '';
    ctrl.isPureMarketplace = LocalNetworkProfile.isPureMarketplacePlatform();

    ctrl.isNew = $stateParams.isNew;
    adnListHelper.setUpBasicList(ctrl, AdUnit, 'fullAdUnitsforSite', {
      moreParams: {site: model.id},
      afterFill: function() {
        ctrl.adUnitsEmpty = _.isEmpty(ctrl.items);
        ctrl.adUnits = ctrl.items;
        ctrl.adUnitsCount = Object.keys(ctrl.items).length;
        ctrl.selAdUnit = _.find(ctrl.items, function() {
          return true;
        });
      }
    });

    ctrl.removeDimensions = function(index) {
      _.pullAt(ctrl.adUnit.dimensions, index);
      ctrl.eventHook.removeControlHook('dims' + index + '.width');
      ctrl.eventHook.removeControlHook('dims' + index + '.height');
    };
    ctrl.addDimensions = function() {
      ctrl.adUnit.dimensions = ctrl.adUnit.dimensions || [];
      ctrl.adUnit.dimensions.push([]);
    };
    ctrl.eventHook = {};

    ctrl.adTagGenerator = adTagLinker(ctrl, {$state: $state});
    ctrl.bulkAction = adUnitBulkAction(ctrl, {$uibModal: $uibModal}, {TimeZones: timezonesResource, Site: Site}, {LocalNetworkProfile: LocalNetworkProfile, localUserProfile: localUserProfile});

    modelComms.addSubscriber(function(data, type) {
      if (!type && data.name) {
        ctrl.model = data;

        if (ctrl.isNew) {
          ctrl.isNew = false;
        }
      }
    });

    ctrl.onAdUnitBeforeSubmit = function() {
      if (ctrl.adUnit.adUnitOption === 'RANGE') {
        ctrl.adUnit.dimensions = null;
      } else {
        ctrl.adUnit.minWidth = null;
        ctrl.adUnit.minHeight = null;
        ctrl.adUnit.height = null;
        ctrl.adUnit.width = null;
      }
    };
  });

export default MODULE_NAME;